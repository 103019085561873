@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --contentHeight: 90%;
}

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
}

/* To remove arrows on number fields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  padding: 19px 16px;
}

/* To remove X mark on google maps' InfoWindow */
button.gm-ui-hover-effect {
  visibility: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Globally styling scrollbars reliably */
body ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
 
body ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body ::-webkit-scrollbar-thumb {
  border-radius: 500px;
  background-color: #222222;
  outline: 1px solid slategrey;
}

/* [TODO] Make select dropdown arrow color dynamic */
/* %23E9EDF2 */
select {
  /* background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23E9EDF2' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important; */
  -moz-appearance:none !important;
  -webkit-appearance: none !important; 
  appearance: none !important;
  /* padding-right: 2rem !important; */
}
.mobileShow{
  display: none !important;
}

@media (max-width: 1024px) {
  .tabletHide {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .mobileHide {
    display: none !important;
  }
  .mobileShow {
    display: flex !important;
  }
}

/* CONTAINERS */

.switchFlex {
  flex-direction: row;
  display: flex;
}

.switchFlexSmall {
  flex-direction: row;
  display: flex;
}

.halfContainer {
  width: 50%;
}

@media (max-width: 1250px) {
  .switchFlexLarge {
    flex-direction: column;
  }
  .partnering {
    max-width: 100%;
  }
}

@media (max-width: 1024px) {
  .halfContainer {
    width: 100%;
  }

  .switchFlex {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .switchFlexSmall {
    flex-direction: column;
  }
}

.title {
  font-size: 54px;
  line-height: 110%; /* 59.4px */
  letter-spacing: -0.5px;
}

h1 {
  font-size: 40px;
  line-height: 110%; /* 44px */
  letter-spacing: -0.4px;
}

.h1 {
  font-size: 40px;
  line-height: 110%; /* 44px */
  letter-spacing: -0.4px;
}

h2 {
  font-size: 32px;
  line-height: 110%; /* 35.2px */
  letter-spacing: -0.4px;
}

.h2 {
  font-size: 32px;
  line-height: 110%; /* 35.2px */
  letter-spacing: -0.4px;
}
  

h3 {
  font-size: 24px;
  line-height: 110%; /* 26.4px */
  letter-spacing: -0.1px;
}

.h3 {
  font-size: 24px;
  line-height: 110%; /* 26.4px */
  letter-spacing: -0.1px;
}

h4 {
  font-size: 24px;
  font-weight: 400;
  line-height: 110%; /* 26.4px */
  letter-spacing: -0.1px;
}

.bodyLarger {
  font-size: 16px;
  font-style: normal;
  line-height: 130%; /* 23.4px */
}

.bodySmaller {
  font-size: 12px;
  line-height: 110%; /* 13.2px */
  letter-spacing: 0.3px;
}

.caption {
  color: var(--Color-Neutral-black, #000);
  font-feature-settings: 'case' on;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: 0.3px;
}

.headerHomeBg {
  background: linear-gradient(0deg, rgba(248, 206, 125, 0.00) 2.52%, rgba(48, 40, 38, 0.60) 87.98%);
  color: #FDFFFE;
}

.headerHome {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 64px;
  padding: 24px 0px;
  justify-content: space-between;
  align-items: center;
  color: #FDFFFE;
  padding-bottom: 5.5%;
}

.mobileMenu {
  z-index: 30;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  right: 0px;
  padding: 32px 64px;
  opacity: 0.95;
  color: white;
}

@media (max-width: 1440px) {
  .headerHome {
    max-width: 100vw;
  }
}

.headerLink {
  display: flex;
  padding: 14px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #FDFFFE;
}

.primaryButton {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #F8CE7D;
  background: var(--Yellow, #F8CE7D);
  color: var(--Green, #1A2E25);
  font-feature-settings: 'case' on;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 19.8px */
  letter-spacing: -0.6px;
  transition: background 0.3s, box-shadow 0.3s; /* Smooth transition effect */
  max-height: 60.8px;
}

.primaryButton:hover {
  background: var(--Dark-Yellow, #F8BD4A);
  box-shadow: 0px 1px 6px 0px rgba(26, 46, 37, 0.50);
}

.roadmapCurrent:hover {
  background: var(--Dark-Yellow, #F8BD4A) !important;
  box-shadow: 0px 1px 6px 0px rgba(26, 46, 37, 0.50) !important;
}

.clearButton {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid;
  font-feature-settings: 'case' on;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 19.8px */
  letter-spacing: -0.6px;
}

.clearButtonDisabled {
  border-radius: 8px;
  border: 2px solid var(--Green-placeholder, #678175);
  background: var(--50, #E5E5E5);
  color: var(--Green-placeholder, #678175);
  font-feature-settings: 'case' on;  
}

.clearButton:hover {
  box-shadow: 0px 1px 6px 0px rgba(26, 46, 37, 0.50);
}

.clearButtonDisabled:hover {
  box-shadow: 0px 0px 0px 0px rgba(26, 46, 37, 0.50);
}

.homeBg {
  background-image: url('assets/homebg.jpeg');
  background-size: cover; /* Adjust height while maintaining aspect ratio */
  background-repeat: no-repeat;
  background-position: center right;
}

.panewrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}


.panewrapperBgGradient {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  background: linear-gradient(0deg, #1A2E25 7.89%, rgba(26, 46, 37, 0.00) 100%);
  color: #FDFFFE;
  min-height: 22vh
}

.panewrapperBgGradient div {
  width: 100%;
  max-width: 1440px;
  padding: 0px 64px;
}

@media (max-width: 1440px) {
  .panewrapperBgGradient div {
    max-width: 100vw;
  }
}

.headerSect {
  display: flex;
  align-items: flex-end;
  gap: 32px;
}

@media (max-width: 1350px) {
  .headerSect {
    gap: 16px;
  }  
}

.buttonSizer { 
  padding: 14px 32px;

}

.headerSect .buttonSizer span {
  bottom: 3px
}
.heroHeaderBg {
  flex-direction: column;
}

.heroHeader {
  color: #FDFFFE;
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding: 52px 64px 0px 52px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  margin: 0 auto;
}

.homeSect1 {
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding: 0px 64px 64px 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  color: #FDFFFE;
}


.homeSect2 {
  display: flex;
  width: 100%;
  max-width: 1440px;  
  padding: 64px;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 48px;
  color: #FDFFFE;
}

@media (max-width: 1440px) {
  .heroHeader, .homeSect2, .homeSect1 {
    max-width: 100vw;
  }
}


@media (max-width: 1250px) {
  .homeSect2 {
    align-items: center;  
  }
}

.homeLogos {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 48px;
  width: 100%;
}

@media (max-width: 768px) {
  .homeLogos {
    max-width: 100%;
  }
}

.nfLogo {
  width: 228px;
  max-width: 228px;
}

.ioLogo {
  width: 202px;
  max-width: 202px;
}

.homeLogos .nfLogo {
  position: relative;
  top: 4px
}

.partnering {
  max-width: 322px;
}

@media (max-width: 1250px) {
  .partnering {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .homeLogos {
    flex-direction: column;
    align-items: center;
  }
  .homeLogos .nfLogo {
    position: relative;
    top: 0px;
  }
  .partnering {
    text-align: center;
  }
}

.homeSect3 {
  width: 100%;
  max-width: 1440px;
  display: flex;
  padding: 64px 104px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.homeSect3Container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.homeSect3Arrow {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.homeSect3ContainerHeightFix {
  height: 56px;
  width: 182px;
}

@media (max-width: 1440px) {
  .homeSect3 {
    max-width: 100vw;
  }
}

@media (max-width: 1220px) {
  .homeSect3 {
    justify-content: center;
  }
  .homeSect3ContainerHeightFix {
    height: fit-content;
    width: 100%;
  }
  .homeSect3Container  {
    width: fit-content;
    flex-direction: column;
    justify-content: center;
  }
  .homeSect3Arrow {
    display: none;
  }
}

.threeWays {
  text-align: center;
  display: flex;
  width: 368px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.threeWays span {
  display: flex;
  width: 240px;
  padding: 16px 8px 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-top: 1px solid var(--Sage, #B4D3C5);
}

.homeSect4 {
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding: 64px 0px;
  justify-content: space-between;
  align-items: center;
  margin: 0px 64px;
}

@media (max-width: 1440px) {
  .homeSect4 {
    max-width: 100vw;
  }
}

.homeSect4Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  padding-right: 64px;
}

.homeSect4Img {
  width: 648px;
  height: 518px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Relax, url("assets/Relax.png") lightgray 50% / cover no-repeat);
}

.programSect4Img {
  width: 420px;
  height: 518px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Relax, url("assets/Relax.png") lightgray 50% / cover no-repeat);
}

@media (max-width: 1400px) {
  /* .homeSect4 {
    flex-direction: column;
    justify-content: center;
  } */
  .homeSect4Break {
   display: none;
  }

  .homeSect4Container {
    padding-right: 24px;
  }

  .programSect4Img { 
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    margin-left: 48px;
    background-position: center right;
  }
}

@media (max-width: 1000px) {
  .homeSect4 {
    flex-direction: column;
    justify-content: center;
  }
  .homeSect4Break {
    display: inline;
   }
  .homeSect4Img { 
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    margin-left: 48px;
    background-position: center right;
  }
}

.homeSect5 {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding: 0px 208px 64px 208px;
  justify-content: space-between;
  align-items: flex-start;  
  color: #FDFFFE;
}

@media (max-width: 1440px) {
  .homeSect5 {
    max-width: 100vw;
  }
}

@media (max-width: 1330px) {
  .homeSect5 {
    padding: 0px 100px 64px 100px;

  }
}


.homeSect5Cont1 {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 389px;
  top: 0;
  padding-top: 64px;
}

.homeSect5Cont2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 512px;
  padding-top: 64px;
}

.homeSect5Cont2 .stepCont {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

@media (max-width: 1000px) {
  .homeSect5 {
    flex-direction: column;
    justify-content: center;
  }
  .homeSect5Cont1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
    margin-bottom: 80px;
  }
  .homeSect5Cont2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .homeSect5 {
    padding: 64px 100px;

  }
}

.homeSect5Number {
  display: flex;
  width: 64px;
  height: 64px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 62px;
  border: 1px solid var(--Yellow, #F8CE7D);
  background: var(--Green, #1A2E25);
  color: var(--Yellow, #F8CE7D);
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.3px;
  position: relative;
}

.numberLine1 {
  width: 50%;
  border-right: 1px solid #F8CE7D;
  height: 120px;
}

.numberLine2 {
  width: 50%;
  border-right: 1px solid #F8CE7D;
  height: 120px;
}

@media (max-width: 600px) {
  .numberLine1 {
    height: 170px;
  }  
  .numberLine2 {
    width: 50%;
    border-right: 1px solid #F8CE7D;
    height: 240px;
  }
}

.homeSect5Number1::before {
  position: absolute;
  background: #F8CE7D;
  display: inline;
  width: 10px;
  height: 300px;
}

.homeSect6 {
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding: 64px;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
}

.homeSect6Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.homeSect6Img {
  width: 648px;
  height: 518px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Relax, url("assets/Meet.png") lightgray 50% / cover no-repeat);
}

@media (max-width: 1220px) {
  .homeSect6 {
    flex-direction: column;
    justify-content: center;
    max-width: 100vw;
  }
  .homeSect6Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
  
  .homeSect6Img { 
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    background-position: center right;
  }
}

.homeSect7 {
  display: flex;
  padding: 104px 64px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  width: 100vw;
  max-width: 1440px;

}

@media (max-width: 1440px) {
  .homeSect6, .homeSect7 {
    max-width: 100vw;
  }
}


.homeSect7Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  max-width: 596px;
  width: 100%;
  color: #FDFFFE;
}

.ul {
  width: 98%;
}

.homeSect7Img {
  width: 648px;
  height: 100%;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Relax, url("assets/Hip.png") lightgray 50% / cover no-repeat);
}


@media (max-width: 1220px) {
  .homeSect7 {
    flex-direction: column;
    justify-content: center;
    max-width: 100vw;
    margin-right: 0px;
  }
  .homeSect7Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    max-width: 544px;
    max-width: 100%;
    color: #FDFFFE;
    margin-right: 24px;
  }
  .homeSect7Img {
    margin-top: 48px;
    width: 100%;
    height: 500px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Relax, url("assets/Hip.png") lightgray 50% / cover no-repeat);
  }
}

.homeSect9 {
  display: flex;
  max-width: 1440px;
  width: 100%;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: #FDFFFE;
}

@media (max-width: 1440px) {
  .homeSect9 {
    max-width: 100vw;
  }
}


.homeFooter {
  display: flex;
  max-width: 1440px;
  width: 100vw;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  gap: 57px;
}

@media (max-width: 1440px) {
  .homeFooter {
    max-width: 100vw;
  }
}


.footerCTLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23.05%;
}
/* 
.ctLogoText {
  width: 235.915px;
  height: 21.022px;
} */

.ctLogoLeaf {
  margin-right: 10px;
}

.ioLogoDark {
  width: 21.34%
}

.nfLogoDark {
  width: 23.17%;
  position: relative;
  top: 23%;
}

.homeFooter p {
  max-width: 600px;
}

.homeFooterCont1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.homeFooterCont2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.isedLogo {
  width: 45.73%;
}

@media (max-width: 1275px) {
  .homeFooterCont1 {
    flex-wrap: wrap;
  }
  /* .nfLogoDark {
    top: 0px;
  } */
  .homeFooterCont1 h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 24px;

  }
  .nfLogoDark {
    top: 13%;
  }
}

@media (max-width: 950px) {
  .homeFooter p {
    max-width: 100%;
    margin-bottom: 24px;
  }
  .homeFooterCont1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 32px
  }
  .nfLogoDark {
    top: 0;
  }
  .footerCTLogo {
    width: 38%;
  }
  .ioLogoDark, .nfLogoDark {
    width: 30%;
  } 
}

@media (max-width: 769px) {
  .footerCTLogo {
    width: 50%;
  }
  .ioLogoDark, .nfLogoDark {
    width: 40%;
  } 
  .isedLogo {
    width: 80%;
  }
  .homeFooterCont2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.homeSect8 {
  display: flex;
  width: 100vw;
  max-width: 1440px;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

@media (max-width: 1440px) {
  .homeSect8 {
    max-width: 100vw;
  }
}

.homeFAQCont {
  max-width: 1084px;
  width: 100%;
}

.faqButton {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--Sage, #B4D3C5);
}

.answer {
  display: flex;
  padding: 16px 24px;
}

.registerCont1, .loginCont1 {
  display: flex;
  height: 50vh;
  padding: 0px 64px 0px 64px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #FDFFFE;

}

.registerCont2, .loginCont2 {
  height: 50vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.registerCont2 {
  background: var(--Relax, url("assets/Listen.png") lightgray 50% / cover no-repeat);
}

.loginCont2 {
  background: var(--Relax, url("assets/Write.png") lightgray 50% / cover no-repeat);
}

.ctLogoLeafReg {
  width: 16.603px;
  height: 15.014px;
  flex-shrink: 0;
  margin-right: 4px;
}

.footerCTLogoReg svg {
  position: relative;
  top: 3px
}

.nfLightRegister{
  width: 140px;
  position: relative;
  top: 1px;
}

.reg-logos {
  background: linear-gradient(0deg, #1A2E25 0%, rgba(26, 46, 37, 0.00) 100%);
  display: flex;
  padding: 190px 24px 18px 24px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  width: 100%;
}

.ioLogoRegister {
  width: 100px;
  position: relative;
  bottom: 12px
}

.regFormCont{
  max-width: 577px;
  width: 100%;
}

.reg-flex {
  justify-content: center;
}

@media (max-width: 1500px) {
  .reg-flex {
    justify-content: flex-end;
  }
}

@media (max-width: 1034px) {
  .reg-logos a {
    display: none;
  }
}



.progBar {
  height: 8px;
}

.progComplete {
  background: var(--Green, #1A2E25);
}

.progIncomplete {
  background: #4F9D7A;
}

.labelMargin {
  color: var(--Green, #1A2E25);
}

.formComponentCont {
  margin-bottom: 24px;
}

input[type="radio"] {
  accent-color: #678175 !important;
  height: 20px;
  width: 20px;
  margin-left: 8px;
  position: relative;
  bottom: 1px;
}

div input[type="checkbox"] {
  accent-color: #678175 !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: 8px !important;
  position: relative !important;
  bottom: 1px !important;
  flex-shrink: 0;
}

div input[type="checkbox"]:disabled + label::before {
  accent-color: #678175 !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: 8px !important;
  position: relative !important;
  bottom: 1px !important;
  flex-shrink: 0;
  opacity: 1;
}


.tooltip {
  border-radius: 8px;
  background: var(--Green, #1A2E25);
  box-shadow: 0px 1px 6px 0px rgba(26, 46, 37, 0.50);
  position: absolute;
  color: #FDFFFE;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  z-index: 1;
}

.tooltipIcon {
  bottom: 3px;
  z-index: 1;
}

.input {
  border-radius: 4px;
  color: #1A2E25;
  border: 1px solid var(--Green-placeholder, #678175);
  padding: 19px 16px;
}

.twoButton {
  gap: 16px
}

.mobileHeaderRegister {
  display: none;
}

@media (max-width: 1220px) {
  .twoThree {
    width: 60%;
    height: 100vh;
    margin: 0 24px;
  }
  .oneThree {
    width: 40%;
  }
  .registerCont1 {
    width: 100%;
    word-break: break-word;
    padding-right: 24px;
  }
  .headerRegister {
    padding: 24px 0px;
  }
}

@media (max-width: 787px) {
  .twoThree {
    width: 100%;
    height: 100vh;
  }
  .oneThree {
    display: none;
  }
  .headerRegisterEip {
    color: #1A2E25 !important;
  }
  .headerRegister {
    display: none;
  }
  .mobileHeaderRegister {
    display: flex;
    padding: 0px 0px 24px 0px;
  }
}

.ReactModal__Overlay--after-open {
  z-index: 100;
}

.ReactModal__Content {
  background: #EFF5F4 !important;
  background-color: #EFF5F4 !important;

}

.loginFormCont {
  gap: 48px;
  display: flex;
  flex-direction: column;
  max-width: 577px;
  width: 100%;
}

.contactCont {
  gap: 48px;
  max-width: 576px;
  margin: 48px auto;
}

.whiteButton {
  color: #FDFFFE !important;
  border-color: #FDFFFE !important;
}

.contactLogoCont {
  display: flex;
  padding: 0px 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  margin: 48px auto;
}

.contactLogoCont div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.contactLogoCont .nfLogoDark {
  top: 0px;
  width: 280px;
  height: 84px;
  position: relative;
}

.contactLogoCont .footerCTLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.contactLogoCont .ctLogoText {
  width: 217.295px;
  height: 19.362px;
}

.contactLogoCont .ctLogoLeaf {
  width: 38.0766px;
  height: 35.04px;
  margin-right: 10px;
}

.contactLogoCont .ioLogoDark {
  width: 240px;
  height: 86.09px;
}


.contact-email {
  position: relative;
  bottom: 3px;
}

.headerContact {
  display: flex;
  max-width: 1440px;
  width: 100vw;
  padding: 24px 64px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1440px) {
  .headerContact {
    max-width: 100vw;
  }
}

.headerContact p {
  color: #FDFFFE;
}

/* SIDEMENU */

.sideMenu {
  display: inline-flex;
  height: 100vh;
  padding: 24px 16px 64px 16px;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  background: var(--Blue, #EFF5F4);
  max-width: 248px;
  min-width: 248px;
}

.sideMenu .collapsedShow {
  display: none;
}

.iconPadding{
  padding: 16px 24px;
}

.sideMenuCollapsed {
  padding: 24px 16px 64px 16px !important;
  max-width: 104px !important;
  min-width: 0px;
}

.sideMenuCollapsed .collapsedHide {
  display: none !important;
}

.sideMenuCollapsed .collapsedShow {
  display: flex !important;
}

div .sideMenuCollapsed .profileHide {
  display: none !important;
}

.profileHide {
  display: none !important;
}

.sideMenuCollapsed .tooltipIcon {
  right: 23px
}

.sideMenuItem:hover {
  font-weight: 600;
}

.activeShow {
  display: none;
}

.sideMenuItem:hover .activeShow, button:hover .activeShow {
  display: inline;
}
.sideMenuItem:hover .activeHide, button:hover .activeHide {
  display: none;
}

.sideMenuActive{
  border-radius: 360px;
  box-shadow: 0px 1px 6px 0px rgba(26, 46, 37, 0.50);
  font-weight: 600;
}

.sideMenuActive .activeHide {
  display: none;
}

.sideMenu .activeItem {
  border-radius: 360px;
  box-shadow: 0px 1px 6px 0px rgba(26, 46, 37, 0.50);
  font-weight: 600;
}

.sideMenu .activeItem .activeHide {
  display: none;
}

.sideMenu .activeShow {
  display: none;
}

.sideMenuActive .activeShow {
  display: flex;
}

.sideMenuCollapsed .collapsedHide {
  display: none !important;
}

.sideMenuCollapsed .collapsedShow {
  display: flex !important;
}

@media (max-width:768px) {
  .collapsedHide {
    display: none !important;
  }
  
  h3.collapsedShow {
    display: flex !important;
  }
  
}

/* DASHBOARD */

.dashboardHeader {
  display: flex;
  width: 100%;
  padding: 8px 24px;
  justify-content: flex-end;
  align-items: center;
}

.dashboardCont {
  display: flex;
  width: 100%;
  padding: 0px 56px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  height: fit-content;
}

.dashboardContInner1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  width: 100%
}

.roadmapCont {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Sage, #B4D3C5);
  background: var(--White, #FDFFFE);
  width: 100%;
}

.roadmapContInner {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
}

.roadmapComplete {
  border-radius: 8px;
  background: var(--Light-Green, #EBF6F0);
  display: flex;
  min-height: 86px;
  padding: 16px;
  align-items: center;
  gap: 24px;
}

.roadmapComplete img {
  width: 24px !important;
  height: 22.5px !important;
}

.roadmapCurrent {
  border-radius: 8px;
  background: var(--Light-Yellow, #F8E7C6);
  display: flex;
  min-height: 86px;
  padding: 16px;
  align-items: center;
  gap: 24px;
}

.roadmapCurrent img {
  width: 24px !important;
  height: 33px !important;
}

.roadmapLocked {
  border-radius: 8px;
  background: var(--30, #F7F7F7);
  display: flex;
  min-height: 86px;
  padding: 16px;
  align-items: center;
  gap: 24px;
  color: var(--Green-placeholder, #678175) !important;
}

.roadmapLocked img {
  width: 24px !important;
  height: 33px !important;
}


.roadmapUnsuccessful {
  border-radius: 8px;
  background: var(--Denied, #FFDCD8);
  display: flex;
  min-height: 86px;
  padding: 16px;
  align-items: center;
  gap: 24px;
}

.roadmapUnsuccessful img {
  width: 22.3px !important;
  height: 22.3px !important;
}


.oppositeRoadMap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-left: 32px;
}

.nextStepCont {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  border-radius: 8px;
  background: var(--Green, #1A2E25);
  color: var(--White, #FDFFFE);
}

.nextStepCont div div, .educationCont div div {
  width: 248px;
}

.educationCont {
  display: flex;
  width: 100%;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid var(--Sage, #B4D3C5);
}

.educationCont h3 {
  margin-bottom: 8px;
}

.pdCont {
  display: flex;
  padding: 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Sage, #B4D3C5);
  width: 100%;
  margin-bottom: 36px;
}

.pdCont div p {
  max-width: 628px;
}

@media (max-width: 1200px) {
  .dashboardContInner1 {
    flex-direction: column;
    gap: 32px;
  }
  .oppositeRoadMap {
    align-items: flex-start;
    gap: 24px;
    margin-left: 0px;
    width: 100%;
  }
  .nextStepFull {
    width: 100%;
    justify-content: space-between;
  }
}


.assessmentModalCont {
  padding: 20px;
  flex-direction: column;
align-items: center;
gap: 24px;
}

.primaryButtonDisabled {
  background: var(--50, #E5E5E5) !important;
  color: var(--Green-placeholder, #678175) !important;
  border-color: var(--Green-placeholder, #678175) !important;
}

.helpCont1 {
  max-width: 582px;
}

.helpCont2 {
  max-width: 816px;
}

.helpBorderActive {
  border-bottom: 8px solid var(--Green-placeholder, #678175);
}

.helpBorderPassive {
  border-bottom: 8px solid var(--Sage, #B4D3C5);
}

.applicationThreeway {
  background: var(--Blue, #FDFFFE);
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.applicationThreeway .threeWays {
  border-radius: 8px !important;
  border: 3px solid #FDFFFE;
  background: #FDFFFE;
}


.applicationThreeway .threeWays:hover {
  border-radius: 8px !important;
  border: 3px solid var(--Sage, #B4D3C5) !important;
  background: var(--Light-Green, #EBF6F0) !important;
}

.applicationCont {
  max-width: 382px;
}

.appImg {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: var(--Relax, url("assets/Listen.png") lightgray 50% / cover no-repeat);
  margin-left: 68px;
}

@media (max-width: 1306px) {
  .applicationCont {
    width: 382px !important;
    flex-shrink: 0;
  }
  .appImg {
    width: 100%;
    height: 100%;
    margin-left: 32px;
    background-position: right;
  }
}

@media (max-width: 1425px) {
  .applicationThreeway h4{
    height: 52.9px;
  }
}

@media (max-width: 1240px) {
  .applicationThreeway p{
    height: 83.19px;
  }
}


@media (max-width: 1180px) {
  .applicationThreeway {
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }
  .threeWays {
    width: 100%;
    max-width: 600px;
  }
  .applicationThreeway p{
    height: auto;
  }
  .applicationThreeway h4{
    height: auto;
  }
}

@media (max-width: 1024px) {
  .applicationCont {
    width: 100% !important;
    max-width: 100%;
  }
  .appImg {
    display: none;
  }
}

.applicationSideMenu {
  background: var(--30, #F7F7F7);
  display: flex;
  height: 100vh;
  padding: 64px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  min-width: 354px
}

.menuOffset {
  margin-left: 354px
}

.applicationSideMenuInner {
  max-width: 226px;
}

.applicationSideMenu .applicationSideItem {
  display: flex;
  margin-bottom: 0px;
  padding: 0px;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: flex-end;
}

.applicationSideMenu .applicationSideItem button {
  margin-bottom: 0px;
  display: flex;
}

.completeCircle {
  border: 1px solid #1A2E25;
  padding: 2px;
  border-radius: 365px;
  margin: 6px;
}

.circle {
  margin: 6px;
}

.applicationSpacer {
  height: 32px;
  width: 100%;
  position: relative;
}

.applicationSpacer div {
  height: 100%;
  width: 1px;
  background: #B4D3C5;
  margin-left: 17px;
}

.blockFormCont { 
  width: 48% !important;
  display: block !important;
}

.blockFormCont:nth-of-type(odd) {
  margin-right: 2%;
}

.modifyForm {
  flex-wrap: wrap;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
}

@media (max-width: 1400px) {
.blockFormCont { 
  width: 44% !important;
  display: block !important;
}

.blockFormCont:nth-of-type(odd) {
  margin-right: 6%;
}

.applicationSideMenu {
  padding: 64px 24px 64px 24px;
  min-width: 274px;
}

.menuOffset {
  margin-left: 274px
}
}

.pdfInput {
  display: flex;
  max-width: 576px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Green-placeholder, #678175);
  padding: 32px;
}

input[type="file"] {
  display: none;
}

.applicationFormWidth {
  max-width: 576px;
}

/* .ReactModal__Overlay .primaryButton { find the one that needs this
  padding: 20px 32px;
  margin: 0px 40px 40px 40px;
} */

.ReactModal__Overlay .twoButton .primaryButton {
  padding: 16px 32px;
  margin: 0px;
}

.ReactModal__Overlay .viewRecModal .primaryButton, .ReactModal__Overlay .rejectionModal .primaryButton {
  padding: 16px 32px;
  margin: 0px;
  margin-bottom: 16px;
}

.programheaderbutton {
  color: #F7F7F7 !important;
}

.ctLogoTextProgram {
  width: 212.3235px;
  height: 18.9198px;
}

.ctLogoLeafProgram {
  width: 37.8594px;
  height: 34.236px;
  margin-right: 20px;
}

.ioLogoDarkProgram {
  width: 210px;
  height: 75.332px;
}

.nfLogoDarkProgram {
  width: 228px;
  height: 68.416px;
  position: relative;
  top: 28px;
}

@media (max-width: 1275px) {

  .nfLogoDarkProgram {
    top: 0px;
  }
}

.resourcesCont {
  display: inline-flex;
  padding: 32px 56px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.firmsCont {
  display: inline-flex;
  padding: 32px 56px;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.flexList {
  display: flex !important;
}

.listComp .contents:nth-of-type(even) {
  background: var(--30, #F7F7F7) !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.listComp .contents:nth-of-type(even) button, .listComp .contents:nth-of-type(even) div {
  background: var(--30, #F7F7F7) !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.listComp button:hover {
  text-decoration: underline;
}

.listComp .contents:hover button, .listComp .contents:hover div, .listComp .contents:hover, .listComp .contents button:hover, .listComp .contents div:hover {
  background: var(--Blue, #EFF5F4) !important;
}

/* .listComp .contents div:hover button {
  background: var(--Blue, #EFF5F4) !important;
} */

.listComp .flexList:nth-of-type(even) {
  background: var(--30, #F7F7F7) !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.listComp .flexList:nth-of-type(even) button, .listComp .flexList:nth-of-type(even) div {
  background: var(--30, #F7F7F7) !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.listComp button:hover {
  text-decoration: underline;
}

.listComp .flexList:hover button, .listComp .flexList:hover div, .listComp .flexList:hover, .listComp .flexList button:hover, .listComp .flexList div:hover {
  background: var(--Blue, #EFF5F4) !important;
}

/* .listComp .flexList div:hover button, .listComp .flexList:hover div button {
  background: var(--Blue, #EFF5F4) !important;
} */

.firmListMapCont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.firmDetailsDesc {
  width: 50%;
}

.firmDetailsCont {
  width: 100%;
  max-width: 675px;
  justify-content: space-between;
  min-height: 104px;
}

.firmDetailsContInner {
  max-width: 208px;
  word-break: break-all;
  width: 100%;
}

.firmDetailsInner {
  width: 100%;
  max-width: 675px;
}

@media (max-width: 768px) {
  .firmSpacer {
    margin-top: 24px;
  }
  .firmDetailsCont  {
    max-width: 100%;
    flex-direction: column;
    gap: 24px;
  }
  .firmDetailsContInner {
    max-width: 100%
  }
}

.adminStatCont {
display: inline-flex;
width: 22%;
padding: 24px 16px;
flex-direction: column;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Sage, #B4D3C5);
margin-bottom: 4%;
}

@media (max-width: 1024px) {
  .adminStatCont {
    width: 30%;
    margin-bottom: 3.33333%;
  }
}

.memDetailsCont1 {
  top: 0;
  position: sticky;
  display: flex;
  width: 100%;
  max-width: 609px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin-right: 24px;
}

.memDetailsCont2 {
  top: 0;
  position: sticky;
  display: flex;
  padding: 32px;
  width: 100%;
  max-width: 524px;
  height: fit-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid var(--Sage, #B4D3C5);
}

.memDetailsExpertCont2 {
  position: sticky;
  top: 0;
  display: flex;
  padding: 32px;
  width: 100%;
  max-width: 360px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 24px;
  border: 3px solid var(--Sage, #B4D3C5);
  background: var(--White, #FDFFFE);
  
}

.memDetailsCont2Inner {
  display: flex;
  height: fit-content;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Blue, #EFF5F4);
}

@media (max-width: 1024px) {
  .memDetailsCont2, .memDetailsCont1 {
    max-width: 100%;
  }
  .memDetailsCont1 {
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 32px;
  }
}

.notesLine {
  border-left: 1px solid var(--Green-placeholder, #678175);
  word-break: break-all;
  margin-left: 8px;
  padding-left: 16px;
}

.memDetailsAccButt img{
  height: 16px;
  width: 16px;
}

.redCircle {
  display: inline-flex;
  background-color: #DE1616;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.pinkCircle {
  display: inline-flex;
  background-color: #FF8EE6;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.magentaCircle {
  display: inline-flex;
  background-color: #EF2DDC;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.purpleCircle {
  display: inline-flex;
  background-color: #9747FF;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.blueCircle {
  display: inline-flex;
  background-color: #111AE5;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.lilacCircle {
  display: inline-flex;
  background-color: #77B1F5;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.tealCircle {
  display: inline-flex;
  background-color: #37CED9;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.yellowCircle {
  display: inline-flex;
  background-color: #E8C300;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.lightGreenCircle {
  display: inline-flex;
  background-color: #AECA40;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.orangeCircle {
  display: inline-flex;
  background-color: #ED8712;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.greenCircle {
  display: inline-flex;
  background-color: #4F9D7A;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}
.darkCircle {
  display: inline-flex;
  background-color: #182342;
  width: 16px;
  height: 16px;
  border-radius: 360px;
  margin: 0px 8px;
}

.pdfViewWindow {
  min-width: 80vw;
  min-height: 80vh;

}

@media (max-width: 820px) {
  .pdfViewWindow {
    min-width: 80vw;
    min-height: 80vh;
  }
}

.appDetailsScoringCont {
  width: 100%;
  max-width: 365px;
  display: flex;
  flex-direction: column;
  position: fixed;
  gap: 40px;
  margin-left: 104px;
}

.spFirmMapButton {
  border-radius: 8px;
  border: 2px solid var(--Green, #1A2E25);
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.gm-style-iw-chr {
  height: 0px !important;
}

.largeGrid {
  min-width: 270px !important;
  max-width: min-content;
  word-break: break-all;
}

.largerGrid {
  min-width: 270px !important;
}

.memDeatilsStickyHeader {
  height: 130px;
  width: calc(100vw - 109px);
  white-space: nowrap;
  position: fixed;
  background-color: white;
  z-index: 100;
  padding: 0px 56px;
}

.memDeatilsList {
  margin-top: 162px;
}

.ReactModal__Overlay .filterModal .primaryButton {
  margin: 0px;
  padding: 16px 32px;

}

.filterModal {
  min-width: 450px;
}

.filterModal p {
  min-width: 200px;
}

.filterModal select {
  max-width: 300px;
}

.massEmailModal .primaryButton {
  margin: 0px
}

.pathCont{
  width: 672px;
  margin-right: 100px;
}

@media (max-width: 1400px) {
  .pathCont{
    width: 672px;
    margin-right: 32px;
  }
}

@media (max-width: 1024px) {
  .learningPageCont{
    flex-direction: column;
    gap: 32px;
  }
  .pathCont{
    width: 100%;
    margin-right: 0px;
  }
}

.eventCard {
  border-radius: 8px;
  border: 1px solid var(--Sage, #B4D3C5);
  background: var(--White, #FDFFFE);
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
}

.eventLink {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 2px solid var(--Green, #1A2E25);
  color: var(--Green, #1A2E25);
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 19.8px */
  letter-spacing: -0.6px;
}

.successCircle {
  display: flex;
  padding: 2px 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 360px;
  border: 2px solid var(--Success, #4F9D7A);
}

.viewRecModal {
  min-width: 50vw;
}

.helpTypeCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.helpType {
  display: flex;
  width: 100%;
  max-width: 324px;
  padding: 56px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  border: 3px solid var(--Sage, #B4D3C5);
  background: var(--20, #FCFCFC);
}

.helpType:hover {
  background: var(--Light-Green, #EBF6F0);
  cursor: pointer;
}

.glossaryCont {
  max-width: 728px;
  margin: 0 auto;
  position: relative;
  height: fit-content;
}

.glossarySect {
  position: relative;
  width: 100%;
  display: flex;
  height: fit-content;
  margin-bottom: 32px;
}

.glossarySectCont {
  display: flex;
  width: 100%;
  padding: 24px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 2px solid var(--Sage, #B4D3C5);
}

.glossaryLetter {
  top: 0;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 40px;
  margin-right: 88px;
  height: fit-content;
}

.homeModal {
  position: fixed; 
  inset: 0px; 
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100
}

.homeModalInner {
  position: absolute;
  inset: 50% auto auto 50%;
  border-radius: 8px;
  background: var(--30, #F7F7F7);
  overflow: auto;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  max-width: 616px;
  margin: 0 24px;
}

.homeModalTextHeadline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 8px;
}

.homeModalTextHeadline h2 {
  color: var(--Green, #1A2E25);
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
  letter-spacing: -0.1px;
}